.game_frame {
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 813px) {
  .iphone-hide {
    display: none !important;
  }

  .navbar {
    display: none;
  }
}
