@import "~bootstrap/scss/functions";

/* Color Theme Swatches in Hex */
$flopchop-1-hex: #4146a6;
$flopchop-2-hex: #00051c;
$flopchop-3-hex: #9498f2;
$flopchop-4-hex: #c4c7f2;
$flopchop-5-hex: #a7b2f2;

/* Color Theme Swatches in RGBA */
$flopchop-1-rgba: rgba(65, 70, 166, 1);
$flopchop-2-rgba: rgba(0, 5, 28, 1);
$flopchop-3-rgba: rgba(148, 152, 242, 1);
$flopchop-4-rgba: rgba(196, 199, 242, 1);
$flopchop-5-rgba: rgba(167, 178, 242, 1);

/* Color Theme Swatches in HSLA */
$flopchop-1-hsla: hsla(237, 43, 45, 1);
$flopchop-2-hsla: hsla(229, 100, 5, 1);
$flopchop-3-hsla: hsla(237, 78, 76, 1);
$flopchop-4-hsla: hsla(236, 63, 85, 1);
$flopchop-5-hsla: hsla(231, 74, 80, 1);

$primary: $flopchop-2-hsla;
$body-bg: $flopchop-4-hsla;
$input-bg: #fff;

#root {
  padding: 0px;
  height: 100vh;
}

body {
  min-height: 100vh;
}

.backgroundTiled {
  background-color: #f4f4f2;
  background-image: url("../static/images/bg-tile.jpg");
  background-size: 290px;
  background-blend-mode: color-burn;
  animation: fadein 200ms ease-in-out;
}

.loginIcon {
  width: 100%;
}

.playerFrame {
  width: 100%;
  height: 100%;
  border: none;
}

.underline {
  border-bottom: 1px solid $flopchop-2-hsla;
}

.capitalize {
  text-transform: capitalize;
}

.footer-metadata {
  position: absolute;
  left: 0px;
  height: 25px;
  width: 120px;
  bottom: 0px;
  background-color: white;
  border-top-right-radius: 5px;

  p {
    color: black;
    // font-family:
    font-size: 9pt;
    margin: auto;
  }
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
