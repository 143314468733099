.avatar {
  $avatar-scale: 2.5;
  width: 100%;

  button {
    width: 20px;
    padding: 0px;
    height: 100%;
  }

  .left {
    float: left;
  }

  .right {
    float: right;
  }

  .previewImage {
    margin: 0 auto;
    display: block;
    float: left;
    width: $avatar-scale * 32px;
    height: $avatar-scale * 32px;
    border: 5px solid white;
    background-image: url("https://play.flopchop.com/Image?Name=Avatars");
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: $avatar-scale * 2047px $avatar-scale * 32px;
    background-color: white;
    transition: all 0.3s ease, padding 0.8s linear;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #04aa6d;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #04aa6d;
    cursor: pointer;
  }
}
